import React from 'react'
import { Container, Row, Col, Badge } from "reactstrap";

export default function Features2() {
  return (
    <div className="features-1 bg-dark">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <Badge color="primary" id="features-2-pill" pill>
              Insight
            </Badge>
            <h1 className="title">The Core of Moonrake</h1>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div className="info info-hover">
              <div className="icon icon-primary iconBGPrimary">
                <i className="fa-solid fa-person-dots-from-line" />
              </div>
              <h4 className="info-title">Meritocracy</h4>
              <p className="description">
                Distributed decision-making power throughout the organization,
                promoting a democratic and collaborative working environment,
                faster decision-making, employee empowerment and a more
                productive, engaged and motivated workforce.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="info info-hover">
              <div className="icon icon-success iconBGSuccess">
                <i className="tim-icons icon-satisfied" />
              </div>
              <h4 className="info-title">Empathy Driven</h4>
              <p className="description">
                We prioritize the well-being and needs of all stakeholders,
                fostering a culture of compassion and understanding, promoting
                sustainability, equity, and creating meaningful and long-term
                relationships, going beyond profit-driven goals and focusing on
                creating a positive impact.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="info info-hover">
              <div className="icon icon-warning iconBGWarning">
                <i className="tim-icons icon-atom" />
              </div>
              <h4 className="info-title">Autotelic</h4>
              <p className="description">
                Independent self-managing teams empower team members to take
                ownership of their work, encouraging innovation, collaboration
                and responsibility, leading to increased productivity and a
                sense of purpose within the organization to achieve company
                goals.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
