import React from 'react'
import {
  Badge,
  Card,
  CardBody,
} from 'reactstrap';
// import "../assets/scss/black-dashboard-pro-react/custom/_timeline.scss"

export default function MachineLearningSeq() {
  return (
    <div className="mls-wrapper">
      <Card className="card-timeline card-plain">
        <CardBody>
          <ul className="timeline timeline-simple">
            <li className="timeline-inverted">
              <div className="timeline-badge danger iconBGDanger">
                <i className="fa-solid fa-id-badge" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color="danger" className="text-white ml-header-title">
                    Identify the business problem or opportunity
                  </Badge>
                </div>
                <div className="timeline-body">
                  <p>
                    The first step in implementing AI/ML in a current
                    application or system is to identify the specific business
                    problem or opportunity that it will be used to solve. This
                    will help to define the goals and objectives of the project,
                    as well as the specific data and resources that will be
                    needed.
                  </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge iconBGSuccess">
                <i className="fa-solid fa-database" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color="success" className="text-white ml-header-title">
                    Gather and preprocess data
                  </Badge>
                </div>
                <div className="timeline-body">
                  <p>
                    Once the business problem or opportunity has been
                    identified, the next step is to gather and preprocess the
                    necessary data. This may include collecting data from
                    various sources, cleaning and formatting the data, and
                    ensuring that it is in a format that can be used by the
                    AI/ML algorithms.
                  </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge iconBGInfo">
                <i className="tim-icons icon-atom" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color="info" className="text-white ml-header-title">
                    Select and train the AI/ML model
                  </Badge>
                </div>
                <div className="timeline-body">
                  <p>
                    After the data has been preprocessed, the next step is to
                    select the appropriate AI/ML model and train it using the
                    data. This may involve experimenting with different models
                    and algorithms to find the one that best fits the problem or
                    opportunity at hand.
                  </p>
                  <hr />
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge iconBGWarning">
                <i className="fa-solid fa-mobile-screen-button" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color="warning" className="text-white ml-header-title">
                    Integrate the AI/ML model into the application or system
                  </Badge>
                </div>
                <div className="timeline-body">
                  <p>
                    Once the model has been trained, it needs to be integrated
                    into the current application or system. This may involve
                    modifying the existing code, creating new interfaces, or
                    building new functionality to support the AI/ML model.
                  </p>
                  <hr />
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge iconBGDefault">
                <i className="fa-solid fa-microscope" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color="default" className="text-white ml-header-title">
                    Test and evaluate the AI/ML model
                  </Badge>
                </div>
                <div className="timeline-body">
                  <p>
                    Before deploying the AI/ML model in a production
                    environment, it should be thoroughly tested and evaluated to
                    ensure that it is functioning as expected. This may involve
                    creating test cases, evaluating performance metrics, and
                    making any necessary adjustments to the model or
                    implementation.
                  </p>
                  <hr />
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge iconBGPrimary">
                <i className="fa-solid fa-server" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color="primary" className="text-white ml-header-title">
                    Deploy the AI/ML model
                  </Badge>
                </div>
                <div className="timeline-body">
                  <p>
                    After the model has been tested and evaluated, it is ready
                    to be deployed in the production environment. This may
                    involve updating the application or system, as well as
                    creating any necessary documentation and training materials
                    for end-users.
                  </p>
                  <hr />
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-badge iconBGRed">
                <i className="fa-solid fa-computer" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge color="red" className="text-white ml-header-title">
                    Monitor and maintain the AI/ML model
                  </Badge>
                </div>
                <div className="timeline-body">
                  <p>
                    After the AI/ML model has been deployed, it is important to
                    monitor its performance and make any necessary updates or
                    adjustments to ensure that it continues to function as
                    expected. This may involve regularly reviewing performance
                    metrics, updating the model as new data becomes available,
                    and troubleshooting any issues that arise.
                  </p>
                  <hr />
                </div>
              </div>
            </li>
          </ul>
        </CardBody>
      </Card>
    </div>
  );
}
