import React from "react";
import { Button, Container } from "reactstrap";
import NavBar from "Components/NavBar";

export default function MoonrakeIntro({scrollRef}) {
  return (
    <div className="header header-4">
      <div className="header-wrapper">
        <NavBar />
        <div className="page-header header-video header-filter">
          <div className="overlay" />
          <video autoPlay muted playsInline>
            <source
              src="https://moonassetswebsite.s3.amazonaws.com/hyperspace.mp4"
              type="video/mp4"
            />
          </video>
          <Container className="text-center">
            <div className="video-text">
              <h2 className="description">Moonrake</h2>
              <h1 className="title">
                Comprehensive Digital 
                Transformation and Resilience Services
              </h1>
              <br />
              <Button
                className="btn-simple btn-neutral"
                color="default"
                onClick={() =>
                  scrollRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                Dare for more
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
