import React, {useState} from 'react'
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";


export default function Footer() {
  const [cageCopied, setCageCopied] = useState(false);
  const [ueiCopied, setUeiCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = (type, text) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        // If successful, update the isCopied state value
        if(type === "CAGE"){
          setCageCopied(true);
          setTimeout(() => {
            setCageCopied(false);
          }, 1500);
        }else{
          setUeiCopied(true);
          setTimeout(() => {
            setUeiCopied(false);
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="3" className="align-self-center">
            <img
              alt="Moonrake"
              height="20"
              src={require('assets/img/moonrake-nobg.png')}
              width="150"
            />
          </Col>
          <Col md="3" className="align-self-center">
            <h2 className="font-weight-bold">Unique Entity ID</h2>
            <p
              className="mt-n4 ueiCopy"
              onClick={() => handleCopyClick('UEI', 'G1RRV5ZPFEV7')}
            >
              {ueiCopied ? 'Copied!' : 'G1RRV5ZPFEV7'}
            </p>
          </Col>
          <Col md="3" className="align-self-center">
            <h2 className="font-weight-bold">CAGE/NCAGE</h2>
            <p
              className="mt-n4 cageCopy"
              onClick={() => handleCopyClick('CAGE', '84NZ6')}
            >
              {cageCopied ? 'Copied!' : '84NZ6'}
            </p>
          </Col>
          <Col md="3" className="d-flex flex-column align-items-center footer-fu-wrapper">
            <h3 className="title">Follow us:</h3>
            <div className="btn-wrapper profile text-left footer-btn-wrapper">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple btn-round-github githubIcon"
                color="default"
                href="https://github.com/moonrakeio"
                id="tooltip985801800"
                target="_blank"
              >
                <i className="fab fa-github" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip985801800">
                Follow us
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple ml-1 btn-round-google googleIcon"
                color="default"
                href="https://workspace.google.com/dashboard"
                id="tooltip260428517"
                target="_blank"
              >
                <i className="fab fa-google" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip260428517">
                Like us
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple ml-1 btn-round-linkedIn linkedInIcon"
                color="default"
                href="https://www.linkedin.com/company/moonrake"
                id="tooltip483040321"
                target="_blank"
              >
                <i className="fab fa-linkedin" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip483040321">
                Follow us
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
