import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col } from "reactstrap";

export default function Features3() {

  const navigate = useNavigate();

  return (
    <div className="features-4">
      <Container>
        <Row className="align-items-center">
          <Col className="mr-auto text-left" lg="3">
            <h1 className="title">AI-powered IT Services</h1>
            <p className="description">
              At Moonrake, we are committed to being an AI-first IT consulting
              firm, leveraging the latest advancements in artificial
              intelligence to provide innovative solutions and drive digital
              transformation for our clients.
            </p>
            <Button
              className="mt-3"
              color="primary"
              onClick={() => navigate('/ai')}
            >
              Our Process <i className="tim-icons icon-double-right" />
            </Button>
          </Col>
          <Col className="p-sm-0" lg="8">
            <Row>
              <Col md="6">
                <div className="info info-primary">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-paper" />
                  </div>
                  <h4 className="info-title">
                    Natural Language Processing (NLP)
                  </h4>
                  <p className="description">
                    Supporting our clients with Natural Language
                    Processing for enhanced intelligence and communication.
                  </p>
                </div>
              </Col>
              <Col md="6">
                <div className="info info-primary">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-vector" />
                  </div>
                  <h4 className="info-title">Computer Vision</h4>
                  <p className="description">
                    Revolutionizing the battlefield with Computer Vision
                    technology.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="info info-primary">
                  <div className="icon icon-white">
                    <i className="fa fa-university" />
                  </div>
                  <h4 className="info-title">Machine Learning</h4>
                  <p className="description">
                    Empowering projects with Machine Learning
                    for enhanced decision-making and mission success.
                  </p>
                </div>
              </Col>
              <Col md="6">
                <div className="info info-primary">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-chart-pie-36" />
                  </div>
                  <h4 className="info-title">Predictive Analytics</h4>
                  <p className="description">
                    Facilitating Data-Driven Decisions with Advanced Predictive
                    Analytics Techniques.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
