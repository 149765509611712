import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.css";
//styles
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "./assets/css/nucleo-icons.css";
// import "./assets/scss/blk-design-system-pro-react.scss?v1.2.0";
// import "./assets/demo/demo.css";
// import "./assets/demo/react-demo.css";
import "./assets/css/nucleo-icons.css";
import './assets/scss/black-dashboard-pro-react.scss';
import "./assets/scss/blk-design-system-pro-react.scss";
import './index.css';


import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
