import React from 'react'
import { Button, Container, Row, Col } from "reactstrap";


export default function Features4() {
  return (
    <div className="features features-5">
      <Container>
        <Row className="align-items-center">
          <Col lg="6">
            <div className="featured-image">
              <img
                alt="..."
                height="600"
                src={require("assets/img/codedotorg.png")}
                width="500"
              />
            </div>
          </Col>
          <Col className="mt-md-5" lg="6">
            <h6 className="category">Guiding Principles for success</h6>
            <h1 className="title">Company Values</h1>
            <Row>
              <Col lg="6" md="6">
                <div className="info">
                  <h4 className="info-title">Commitment</h4>
                  <p className="description">
                    We hold a steadfast commitment of ethical
                    conduct, in accordance with the mission and values of our
                    clients and partners.
                  </p>
                </div>
                <div className="info">
                  <h4 className="info-title">Diligence</h4>
                  <p className="description">
                    Our quest for excellence is relentless, ensuring that our operations
                    evolve to set benchmarks in effectiveness and efficiency for our services.
                  </p>
                </div>
              </Col>
              <Col lg="6" md="6">
              <div className="info">
                  <h4 className="info-title">Onus</h4>
                  <p className="description">
                    We embrace the onus of service, recognizing our role in 
                    supporting the diverse needs of our country and its citizens 
                    with utmost integrity and responsibility.
                  </p>
                </div>
                <div className="info">
                  <h4 className="info-title">Engagement</h4>
                  <p className="description">
                  We champion collaborative efforts and collective engagement, 
                  creating an environment that unites our team in achieving shared goals.
                  </p>
                </div>
              </Col>
              {/* <Button
                className="btn-link ml-4"
                color="primary"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Why we are different{" "}
                <i className="tim-icons icon-double-right" />
              </Button> */}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
