import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import "./App.css";
import LandingPage from "Screens/LandingPage";
import Footer from "Components/Footer";
import BlogPage from "Screens/BlogPage";
import MLAIPage from "Screens/MLAIPage";
import { BlogServices } from "Services/BlogServices";
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 2, refetchOnWindowFocus: true } },
});
function App() {
  const blogServices = new BlogServices()
  const prefetch = async() => {
   await queryClient.prefetchQuery('blog', () => blogServices.getAllBlogPosts());

  }
  prefetch();
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {/* <NavBar /> */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/insights" element={<BlogPage />} />
          <Route path="/ai" element={<MLAIPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
