import React from 'react'
import { Button, Row, Col, Container, Badge } from 'reactstrap';


export default function Features5() {
  return (
    <div className="features-3 bg-dark">
      <Container fluid>
        <Row>
          <Col className="mr-auto ml-auto" md="6">
            <h1 className="title">Our Philosophy</h1>
            <h4 className="description">
              Creating software that is easy to understand, maintain, and
              modify, with minimal complexity and unnecessary features in order
              to achieve a robust, efficient, and user-friendly system.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto" md="5">
            <div className="info info-horizontal">
              <div className="icon icon-success iconBGSuccessAlt">
                <i className="tim-icons icon-components" />
              </div>
              <div className="description">
                <h3 className="info-title">Modularity</h3>
                <p>
                  A software design philosophy that prioritizes modularity
                  emphasizes on breaking down complex systems into smaller,
                  independent components that can be easily reused, modified,
                  and maintained. This approach allows developers to work on
                  specific parts of the system without affecting the entire
                  system, and makes it easier to add new features or fix bugs.
                </p>
                {/* <Button
                  className="btn-link"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Explore now <i className="tim-icons icon-minimal-right" />
                </Button> */}
              </div>
            </div>
          </Col>
          <Col className="mr-auto" md="5">
            <div className="info info-horizontal">
              <div className="icon icon-warning iconBGWarningAlt">
                <i className="tim-icons icon-chart-bar-32"/>
              </div>
              <div className="description">
                <h3 className="info-title">Scalability</h3>
                <p>
                  A design philosophy that prioritizes scalability ensures that
                  the software is able to handle an increasing workload, whether
                  it's more users or more data, without a decrease in
                  performance. This can be achieved through the use of
                  technologies such as cloud computing, load balancing, and data
                  partitioning.
                </p>
                {/* <Button
                  className="btn-link"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Explore now <i className="tim-icons icon-minimal-right" />
                </Button> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto" md="5">
            <div className="info info-horizontal">
              <div className="icon icon-danger iconBGDanger">
                <i className="tim-icons icon-sound-wave" />
              </div>
              <div className="description">
                <h3 className="info-title">Maintainability</h3>
                <p>
                  A design philosophy that prioritizes maintainability focuses
                  on creating software that is easy to understand, modify, and
                  update, even as the system evolves over time. This can be
                  achieved through the use of code conventions, documentation,
                  and testing.
                </p>
                {/* <Button
                  className="btn-link"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Explore now <i className="tim-icons icon-minimal-right" />
                </Button> */}
              </div>
            </div>
          </Col>
          <Col className="mr-auto" md="5">
            <div className="info info-horizontal">
              <div className="icon icon-info iconBGInfo">
                <i className="tim-icons icon-tap-02" />
              </div>
              <div className="description">
                <h3 className="info-title">
                  Usability
                  {/* <Badge color="info" pill>
                    v2.0
                  </Badge> */}
                </h3>
                <p>
                  A design philosophy that prioritizes usability ensures that
                  the software is easy to use and understand for the end-users.
                  This approach focuses on the user experience and aims to
                  create software that is intuitive, efficient, and easy to
                  navigate. This can be achieved through user research, testing,
                  and user-centered design practices
                </p>
                {/* <Button
                  className="btn-link"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Explore now <i className="tim-icons icon-minimal-right" />
                </Button> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
