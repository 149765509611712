import React, {useRef} from 'react'
import "../App.css";

import Features from 'Components/Features';
import Features2 from 'Components/Features2';
import Features3 from 'Components/Features3';
import MoonrakeIntro from 'Components/MoonrakeIntro';
import Features4 from 'Components/Features4';
import ApplyNowLandingPage from 'Components/ApplyNowLandingPage';
import FAQ from 'Components/FAQ';
import ContactUsLarge from 'Components/ContactUsLarge';
import Features5 from 'Components/Features5';


export default function LandingPage() {
  const scrollRef = useRef(null)
  const contactRef = useRef(null)
  return (
    <div>
      <MoonrakeIntro scrollRef={scrollRef} />
      <Features scrollRef={scrollRef} contactRef={contactRef} />
      <Features2 />
      <Features3 />
      <Features5 />
      <Features4 />
      <ApplyNowLandingPage />
      <FAQ />
      <ContactUsLarge contactRef={contactRef}/>
    </div>
  );
}
