import React, {useState} from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
} from "reactstrap";

export default function FAQ() {
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [collapse5, setCollapse5] = useState(false);
  
  return (
    <div className="cd-section" id="accordion">
      {/* ********* Accordion 1 ********* */}
      <div className="accordion-1">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="title mb-4 mt-5">Frequently Asked Questions</h2>
              <div className="section-space" />
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="12">
              <div className="accordion">
                <Card>
                  <CardHeader>
                    <h5 className="mb-0">
                      <Button
                        className="w-100 text-left"
                        color="link"
                        aria-expanded={collapse1}
                        onClick={() => setCollapse1(!collapse1)}
                      >
                        What services does Moonrake offer?
                        <i className="tim-icons icon-minimal-down float-right" />
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={collapse1}>
                    <CardBody>
                      <ul>
                        <li className="faqBullet">
                          DevSecOps consulting and implementation
                        </li>
                        <li className="faqBullet">
                          Cloud engineering and architecture
                        </li>
                        <li className="faqBullet">
                          Software development and maintenance
                        </li>
                        <li className="faqBullet">
                          Microservices development and deployment
                        </li>
                        <li className="faqBullet">
                          AI and Machine Learning consulting and implementation
                        </li>
                        <li className="faqBullet">
                          Cybersecurity and data protection services
                        </li>
                        <li className="faqBullet">NLP and text analytics</li>
                        <li className="faqBullet">
                          Command and Control systems consulting and
                          implementation
                        </li>
                        <li className="faqBullet">
                          IT strategy and digital transformation consulting
                        </li>
                        <li className="faqBullet">
                          IT Operations and Managed Services
                        </li>
                      </ul>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="mb-0">
                      <Button
                        className="w-100 text-left "
                        color="link"
                        aria-expanded={collapse2}
                        onClick={() => setCollapse2(!collapse2)}
                      >
                        How does Moonrake approach DevSecOps implementations?
                        <i className="tim-icons icon-minimal-down float-right" />
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={collapse2}>
                    <CardBody>
                      Moonrake approaches DevSecOps implementations by utilizing
                      a combination of cutting-edge technology and best
                      practices to ensure that our clients have the most secure
                      and efficient software development processes in place. Our
                      approach includes integrating security measures into the
                      entire software development lifecycle (SDLC) and utilizing
                      a culture of collaboration and continuous improvement to
                      ensure that our clients' systems are always up-to-date and
                      protected against the latest security threats.
                      Additionally, we employ a Lean software delivery method to
                      remove any impediments and improve risk aversion and tech
                      superiority to deliver applications at the rate of
                      relevancy.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="mb-0">
                      <Button
                        className="w-100 text-left "
                        color="link"
                        aria-expanded={collapse3}
                        onClick={() => setCollapse3(!collapse3)}
                      >
                        How does Moonrake integrate AI into their consulting
                        services?
                        <i className="tim-icons icon-minimal-down float-right" />
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={collapse3}>
                    <CardBody>
                      Moonrake integrates AI into their consulting services by
                      utilizing advanced machine learning techniques and
                      algorithms to provide clients with more efficient and
                      effective solutions. Our team of experts in AI and data
                      science work closely with clients to understand their
                      specific needs and tailor our approach to fit those needs.
                      We offer services such as AI strategy development, model
                      development and deployment, and ongoing AI maintenance and
                      optimization to ensure that our clients stay ahead of the
                      curve in terms of utilizing AI technology.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="mb-0">
                      <Button
                        className="w-100 text-left"
                        color="link"
                        aria-expanded={collapse4}
                        onClick={() => setCollapse4(!collapse4)}
                      >
                        How does Moonrake stay current with the latest
                        technology trends?
                        <i className="tim-icons icon-minimal-down float-right" />
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={collapse4}>
                    <CardBody>
                      Moonrake stays current with the latest technology trends
                      by continuously researching and learning about new
                      advancements in the industry. We attend conferences,
                      workshops, and networking events to stay up-to-date on the
                      latest developments in our field. Additionally, we have a
                      dedicated research and development team that continuously
                      tests and evaluates new technologies to see how they can
                      be applied to our clients' needs. We also have a strong
                      network of partnerships and collaborations with other
                      leading technology companies to ensure that we are always
                      at the forefront of innovation.
                    </CardBody>
                  </Collapse>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="mb-0">
                      <Button
                        className="w-100 text-left"
                        color="link"
                        aria-expanded={collapse5}
                        onClick={() => setCollapse5(!collapse5)}
                      >
                        How does Moonrake handle data privacy and security for
                        clients?
                        <i className="tim-icons icon-minimal-down float-right" />
                      </Button>
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={collapse5}>
                    <CardBody>
                      Moonrake takes data privacy and security very seriously.
                      We have a dedicated team of experts who specialize in data
                      privacy and security, who work closely with our clients to
                      ensure that their data is protected and kept secure at all
                      times. We use a combination of industry-leading
                      technologies, best practices, and stringent security
                      protocols to safeguard client data. Additionally, we
                      regularly conduct security audits, vulnerability
                      assessments, and penetration testing to identify and
                      address any potential vulnerabilities. Furthermore, we
                      stay current with the latest security regulations and
                      compliance requirements to ensure that our clients meet
                      industry standards for data privacy and security.
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ********* END Accordion 1 ********* */}
    </div>
  );
}
