import axios from "axios";

export class BlogServices {

  async getAllBlogPosts() {
    const response = await axios.get('https://api.moonrake.io/blogs', {
      headers: 'Content-Type: application/json',
    });
    return response.data;
  }
}