import React from 'react'
import NavBar from 'Components/NavBar';

import { useQuery } from 'react-query';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { BlogServices } from 'Services/BlogServices';
import IndividualBlogPost from 'Components/IndividualBlogPost';


export default function BlogPage() {
  const blogServices = new BlogServices()
  const blogQuery = useQuery('blog', () => blogServices.getAllBlogPosts());
  return (
    <div className='blog-page-container'>
      <NavBar />
      <div className="blogs-5">
        <Container className='blogs-container'>
          <Row>
            <Col md="10">
              <h2 className="title">Latest Insights</h2>
              <Row>
                {!blogQuery.isLoading &&
                  blogQuery.data.response.filter((blog) => blog.body?.storage?.value?.length > 0)
                  .map((blog, index) => (
                    <IndividualBlogPost
                      key={blog.id}
                      blogId={blog.id}
                      title={blog.title}
                      body={blog.body.storage.value}
                      labels={blog.metadata.labels.results}
                      author={blog.history.createdBy.publicName}
                      isFeatured={index < 3} // Pass `isFeatured` as true for the first three posts
                    />
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
