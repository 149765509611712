import React from 'react'
import { Button, Container, Row, Col } from "reactstrap";


export default function Features({ contactRef, scrollRef }) {
  const scrollToContact = () => {
    if (contactRef && contactRef.current) {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
        console.log('Ref is not attached to a DOM element');
    }
  };
  return (
    <div ref={scrollRef} className="features-2">
      <Container>
        <Row className="align-items-center">
          <Col className="mr-auto text-left" lg="3" md="8">
            <h1 className="title">We Provide Formidable Solutions</h1>
            <p className="description">
            Our strategic collaborations involve partnering with mid-sized technology consulting firms boasting a proven track record in government contracting.
            </p>
            <Button
              className="btn-simple"
              color="info"
              onClick={scrollToContact}
            >
              Partner With Us <i className="tim-icons icon-minimal-right" />
            </Button>
          </Col>
          <Col lg="8" md="12">
            <Row>
              <Col lg="4" md="4">
                <div className="info text-left bg-info">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-spaceship" />
                  </div>
                  <h4 className="info-title">DevSecOps</h4>
                  <p className="description">
                    DevSecOps implementation, including security, compliance,
                    and continuous integration & deployment.
                  </p>
                </div>
              </Col>
              <Col lg="4" md="4">
                <div className="info text-left bg-danger info-raised">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-cloud-upload-94" />
                  </div>
                  <h4 className="info-title">Cloud Engineering</h4>
                  <p className="description">
                    Providing expertise in designing and building cloud-native
                    systems, including infrastructure as code, containerization,
                    and multi-cloud strategies.
                  </p>
                </div>
              </Col>
              <Col lg="4" md="4">
                <div className="info text-left bg-default">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-molecule-40" />
                  </div>
                  <h4 className="info-title">Microservices Development</h4>
                  <p className="description">
                    Breaking down monolithic applications into microservices,
                    and providing guidance on deployment, scaling, and
                    monitoring.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="4">
                <div className="info text-left bg-primary">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-bank" />
                  </div>
                  <h4 className="info-title">Security & Compliance</h4>
                  <p className="description">
                    Security and compliance services such as vulnerability
                    assessments, penetration testing, and remediation guidance.
                  </p>
                </div>
              </Col>
              <Col lg="4" md="4" >
                <div className="info text-left bg-warning info-raised">
                  <div className="icon icon-white">
                    <i className="tim-icons icon-user-run" />
                  </div>
                  <h4 className="info-title">Agile Development</h4>
                  <p className="description">
                    Providing training and coaching on agile methodologies,
                    including Scrum and Kanban, as well as Lean software
                    development principles.
                  </p>
                </div>
              </Col>
              <Col lg="4" md="4">
                <div className="info text-left bg-success">
                  <div div className="icon icon-default">
                    <i className="tim-icons icon-laptop" />
                  </div>
                  <h4 className="info-title">Platform Engineering</h4>
                  <p className="description">
                    Specializing in the creation and maintenance of scalable
                    and efficient digital platforms, focusing on automation,
                    platform reliability, and innovative tech solutions.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
