import React from 'react'
import { Modal, Button, Col, Badge } from 'reactstrap'

export default function BlogModal({modalOpen, setModalOpen, body, title, labels}) {
  return (
    <div>
      <Modal
        size="xl"
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="bg-dark"
      >
        <div className="blog-wrapper">
          <div className='blog-title-tag-container'>
            <h3 className="title">{title}</h3>
            <div className='blog-title-placeholder'></div>
              <div className="blog-tags">
                { labels.length > 0 && 'Tags:'}
                {labels.length > 0 && labels.map(label => {
                  let newLabel = label.name
                  newLabel = newLabel.replace(/-/g, ' ')
                  return (
                  <Badge color="primary" className="ml-1 blog-tag-badge">
                    {newLabel}
                  </Badge>
                )}) }
              </div>
          </div>
          <div
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: body }}
          />
          <Button
            onClick={() => setModalOpen(false)}
            className="close-blog-modal"
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}
