import React, { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

export default function NavBar() {
  const navigate = useNavigate()
  const handleLogoClick = useCallback(() => navigate('/'), [navigate])
  return (
    <Navbar className="navbar-transparent" expand="lg">
      <Container>
        <div className="navbar-translate">
          <button className="navbar-toggler" id="example-header-4">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
          <NavbarBrand onClick={handleLogoClick} className='moonrake-nav-logo'>
            <div>
              <img
                alt="Moonrake"
                height="20"
                src={require('assets/img/moonrake-nobg.png')}
                width="150"
              />
            </div>
          </NavbarBrand>
        </div>
        <UncontrolledCollapse navbar toggler="#example-header-4" className='justify-content-end'>
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a onClick={(e) => e.preventDefault()}>
                  M<span>oo</span>nrake
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button className="navbar-toggler" id="example-header-4">
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="mx-auto" navbar>
            <NavItem className="active">
              <NavLink to="/" href="/" >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/insights" >
                Insights
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/ai" >
                AI/ML
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                Products
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#pablo" onClick={(e) => e.preventDefault()}>
                Contact Us
              </NavLink>
            </NavItem> */}
          </Nav>
          <Nav className="nav navbar-right" navbar>
            <NavItem>
              <NavLink href="https://github.com/moonrakeio" target="_blank">
                <i className="fab fa-github githubIcon" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://workspace.google.com/dashboard"
                target="_blank"
              >
                <i className="fab fa-google googleIcon" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://www.linkedin.com/company/moonrake"
                target="_blank"
              >
                <i className="fab fa-linkedin linkedInIcon" />
              </NavLink>
            </NavItem>
          </Nav>
        </UncontrolledCollapse>
      </Container>
    </Navbar>
  );
}
