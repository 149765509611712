import MachineLearningSeq from 'Components/MachineLearningSeq';
import NavBar from 'Components/NavBar';
import React from 'react'



export default function MLAIPage() {
  return (
    <div className='ai-container'>
      <NavBar />
      <div className="machine-learning-container">
        <MachineLearningSeq />
      </div>
    </div>
  );
}
