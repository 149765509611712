import React, {useState} from 'react'
import axios from "axios";
import classnames from "classnames";
import Lottie from "lottie-react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import * as EmailValidator from 'email-validator';
import { checkmark } from 'assets/lottie/check-mark';
import { moonAnimated } from 'assets/lottie/moon';

export default function ContactUsLarge({contactRef}) {
    const [emailContact1Focus, setEmailContact1Focus] = useState(false);
    const [firstNameContact1Focus, setFirstNameContact1Focus] = useState(false);
    const [lastNameContact1Focus, setLastNameContact1Focus] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [messageText, setMessageText] = useState('');
    const [notRobot, setNotRobot] = useState(false);
    const [sent, setSent] = useState(false)
    const [error, setError] = useState('')

    const handleClick = () => {
      if(firstName && lastName && email && messageText && notRobot){
        if(!EmailValidator.validate(email)){
          setError('Please enter a valid email address.')
          return;
        }
        axios
          .post(
            'https://hooks.slack.com/services/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',
            JSON.stringify(
              {
                blocks: [
                  {
                    type: 'header',
                    text: {
                      type: 'plain_text',
                      text: 'New Contact Request',
                    },
                  },
                  {
                    type: 'divider',
                  },
                  {
                    type: 'section',
                    text: {
                      type: 'mrkdwn',
                      text: `*First Name:* ${firstName}`,
                    },
                  },
                  {
                    type: 'section',
                    text: {
                      type: 'mrkdwn',
                      text: `*Last Name:* ${lastName}`,
                    },
                  },
                  {
                    type: 'section',
                    text: {
                      type: 'mrkdwn',
                      text: `*Email:* ${email}`,
                    },
                  },
                  {
                    type: 'section',
                    text: {
                      type: 'mrkdwn',
                      text: `*Message:* ${messageText}`,
                    },
                  },
                  {
                    type: 'divider',
                  },
                ],
              },
              {
                withCredentials: false,
                transformRequest: [
                  (data, headers) => {
                    delete headers.post['Content-Type'];
                    return data;
                  },
                ],
              }
            )
          )
          .then((res) => {
            if (res.status === 200) {
              setSent(true);
              setTimeout(() => {
                setSent(false);
              }, 4000);
              setFirstName('');
              setLastName('');
              setEmail('');
              setMessageText('');
              setNotRobot(false);
              if (error) {
                setError('');
              }
            } else {
              alert('Message failed to send.');
            }
          });
      }
      
    }
  return (
    <div
      className="contactus-1 section-image"
      style={{
        backgroundImage: "url(" + require("assets/img/moonLake.jpg") + ")",
      }}
      ref={contactRef}
    >
      <Container>
        <Row className="mt-5 mb-5">
          <Col md="5">
            <h2 className="title">Get in Touch</h2>
            <h4 className="description">
              Whether it's a quick inquiry or a detailed project consultation,
              we're here to help. Contact us today and let's work together to
              drive your success.
            </h4>
          </Col>
          <Col className="ml-auto mr-auto" md="5">
            {sent && 
            (<div className="checkOverlay">
              <Lottie animationData={moonAnimated} />
            </div>)}
            <Card className="card-contact card-raised">
              <CardHeader className="text-center">
                <CardTitle tag="h4">Contact Us</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <label>First name</label>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": firstNameContact1Focus,
                      })}
                    >
                      <div className="input-group-prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-caps-small" />
                        </InputGroupText>
                      </div>
                      <Input
                        aria-label="First Name..."
                        placeholder="First Name..."
                        type="text"
                        value={firstName}
                        onFocus={() => setFirstNameContact1Focus(true)}
                        onBlur={() => setFirstNameContact1Focus(false)}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col className="pl-2" md="6">
                    <FormGroup>
                      <label>Last name</label>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": lastNameContact1Focus,
                        })}
                      >
                        <div className="input-group-prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-caps-small" />
                          </InputGroupText>
                        </div>
                        <Input
                          aria-label="Last Name..."
                          placeholder="Last Name..."
                          type="text"
                          value={lastName}
                          onFocus={() => setLastNameContact1Focus(true)}
                          onBlur={() => setLastNameContact1Focus(false)}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label>Email address</label>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": emailContact1Focus,
                    })}
                  >
                    <div className="input-group-prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </div>
                    <Input
                      placeholder="Email Here..."
                      type="text"
                      value={email}
                      onFocus={() => setEmailContact1Focus(true)}
                      onBlur={() => setEmailContact1Focus(false)}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                  <p className='emailError'>{error && error}</p>
                </FormGroup>
                <FormGroup>
                  <label>Your message</label>
                  <Input
                    id="message-2"
                    name="message"
                    rows="6"
                    type="textarea"
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                </FormGroup>
                <Row>
                  <Col md="6">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={notRobot}
                          onChange={() => setNotRobot(!notRobot)}
                        />
                        <span className="form-check-sign" />
                        I'm not a robot
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <Button
                      className="btn-round pull-right"
                      color="primary"
                      onClick={handleClick}
                    >
                      {!sent ? "Send Message": "Sent!"}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
