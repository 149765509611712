import React, {useState} from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  CardFooter,
} from 'reactstrap';
import { readingTime } from 'reading-time-estimator';
import BlogModal from './BlogModal';

export default function IndividualBlogPost({title, body, blogId, labels, author, isFeatured}) {
  const [modalOpen, setModalOpen] = useState(false);
  const {text} = readingTime(body, 200)
  const imageURL = `https://moonassetswebsite.s3.amazonaws.com/blog-assets/${blogId}.png`;

  const authorImage = author === "Kai Prout" 
  ? require('assets/img/kai.png') 
  : author === "Suraya Bradshaw"
  ? require('assets/img/suraya.png')
  : require('assets/img/default-avatar.png'); // Default image if needed

  return (
    <Col lg="4" md="6">
      <Card
        className="card-blog card-plain blog-outer-click"
        onClick={(e) => setModalOpen(!modalOpen)}
      >
        <div className="card-image">
          <a onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img rounded"
              src={imageURL}
              onError={(e) => e.target.src = require('assets/img/steven-roe.jpg')}
            />
          </a>
        </div>
        <CardBody>
        {isFeatured && <h6 className="category text-primary">Featured</h6>}
          <CardTitle tag="h4">
            <a onClick={(e) => e.preventDefault()}>{title}</a>
          </CardTitle>
          <p className="card-description">{body.substring(3, 100) + '...'}</p>
          <CardFooter>
            <div className="author">
              <img
                alt="..."
                className="avatar img-raised"
                src={authorImage}
              />
              <span className="ml-1 blog-sub-text">{author}</span>
            </div>
            <div className="stats stats-right blog-sub-text">
              <i className="tim-icons icon-watch-time" />
              {text}
            </div>
          </CardFooter>
        </CardBody>
      </Card>
      <BlogModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        body={body}
        title={title}
        labels={labels}
      />
    </Col>
  );
}
