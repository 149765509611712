export const moonAnimated = {
  nm: 'Moon',
  mn: '',
  layers: [
    {
      ty: 4,
      nm: 'Shape Layer 7',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [79.666, 79.666, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [-76.896, -48.986, 0], ix: 2 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Rectangle 2',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-12.457, -4.717],
                          [-18.009, -5.79],
                          [-32.703, 8.366],
                          [-31.838, 13.954],
                          [-26.286, 15.027],
                          [-11.592, 0.871],
                        ],
                      },
                    ],
                    t: 30,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-12.457, -4.717],
                          [-18.009, -5.79],
                          [-32.703, 8.366],
                          [-31.838, 13.954],
                          [-26.286, 15.027],
                          [-11.592, 0.871],
                        ],
                      },
                    ],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 120,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-12.457, -4.717],
                          [-18.009, -5.79],
                          [-32.703, 8.366],
                          [-31.838, 13.954],
                          [-26.286, 15.027],
                          [-11.592, 0.871],
                        ],
                      },
                    ],
                    t: 150,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 180,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-12.457, -4.717],
                          [-18.009, -5.79],
                          [-32.703, 8.366],
                          [-31.838, 13.954],
                          [-26.286, 15.027],
                          [-11.592, 0.871],
                        ],
                      },
                    ],
                    t: 210,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-12.457, -4.717],
                          [-18.009, -5.79],
                          [-32.703, 8.366],
                          [-31.838, 13.954],
                          [-26.286, 15.027],
                          [-11.592, 0.871],
                        ],
                      },
                    ],
                    t: 239,
                  },
                ],
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 9, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-266.375, -357.625], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Rectangle 1',
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-8.394, -8.904],
                          [-13.947, -9.978],
                          [-28.641, 4.178],
                          [-27.776, 9.767],
                          [-22.223, 10.84],
                          [-7.529, -3.316],
                        ],
                      },
                    ],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 30,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-8.394, -8.904],
                          [-13.947, -9.978],
                          [-28.641, 4.178],
                          [-27.776, 9.767],
                          [-22.223, 10.84],
                          [-7.529, -3.316],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-8.394, -8.904],
                          [-13.947, -9.978],
                          [-28.641, 4.178],
                          [-27.776, 9.767],
                          [-22.223, 10.84],
                          [-7.529, -3.316],
                        ],
                      },
                    ],
                    t: 120,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 150,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [-8.394, -8.904],
                          [-13.947, -9.978],
                          [-28.641, 4.178],
                          [-27.776, 9.767],
                          [-22.223, 10.84],
                          [-7.529, -3.316],
                        ],
                      },
                    ],
                    t: 180,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 210,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [1.772, 1.84],
                          [1.294, -1.247],
                          [0, 0],
                          [-1.772, -1.84],
                          [-1.294, 1.247],
                          [0, 0],
                        ],
                        o: [
                          [-1.772, -1.84],
                          [0, 0],
                          [-1.294, 1.247],
                          [1.772, 1.84],
                          [0, 0],
                          [1.294, -1.247],
                        ],
                        v: [
                          [0.108, -17.402],
                          [-5.444, -18.476],
                          [-20.139, -4.32],
                          [-19.273, 1.269],
                          [-13.721, 2.342],
                          [0.973, -11.814],
                        ],
                      },
                    ],
                    t: 239,
                  },
                ],
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 9, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-227.125, -336.125], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 1',
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-292.75, -376.5],
                          [-299.375, -375.75],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-265.25, -317.875],
                          [-266.375, -324.125],
                          [-272.375, -323.625],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-242.75, -366.125],
                          [-249.625, -373.125],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-295.5, -340.5],
                          [-296.75, -346.375],
                          [-303.125, -346],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-292.125, -370],
                        ],
                      },
                    ],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-282.313, -387.063],
                          [-288.938, -386.313],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-252.625, -330.75],
                          [-253.75, -337],
                          [-259.75, -336.5],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-274.938, -334.063],
                          [-281.813, -341.063],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-302.75, -333.25],
                          [-304, -339.125],
                          [-310.375, -338.75],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-281.688, -380.563],
                        ],
                      },
                    ],
                    t: 30,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-292.75, -376.5],
                          [-299.375, -375.75],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-265.25, -317.875],
                          [-266.375, -324.125],
                          [-272.375, -323.625],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-242.75, -366.125],
                          [-249.625, -373.125],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-295.5, -340.5],
                          [-296.75, -346.375],
                          [-303.125, -346],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-292.125, -370],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-282.313, -387.063],
                          [-288.938, -386.313],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-252.625, -330.75],
                          [-253.75, -337],
                          [-259.75, -336.5],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-274.938, -334.063],
                          [-281.813, -341.063],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-302.75, -333.25],
                          [-304, -339.125],
                          [-310.375, -338.75],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-281.688, -380.563],
                        ],
                      },
                    ],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-292.75, -376.5],
                          [-299.375, -375.75],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-265.25, -317.875],
                          [-266.375, -324.125],
                          [-272.375, -323.625],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-242.75, -366.125],
                          [-249.625, -373.125],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-295.5, -340.5],
                          [-296.75, -346.375],
                          [-303.125, -346],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-292.125, -370],
                        ],
                      },
                    ],
                    t: 120,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-282.313, -387.063],
                          [-288.938, -386.313],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-252.625, -330.75],
                          [-253.75, -337],
                          [-259.75, -336.5],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-274.938, -334.063],
                          [-281.813, -341.063],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-302.75, -333.25],
                          [-304, -339.125],
                          [-310.375, -338.75],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-281.688, -380.563],
                        ],
                      },
                    ],
                    t: 150,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-292.75, -376.5],
                          [-299.375, -375.75],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-265.25, -317.875],
                          [-266.375, -324.125],
                          [-272.375, -323.625],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-242.75, -366.125],
                          [-249.625, -373.125],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-295.5, -340.5],
                          [-296.75, -346.375],
                          [-303.125, -346],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-292.125, -370],
                        ],
                      },
                    ],
                    t: 180,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-282.313, -387.063],
                          [-288.938, -386.313],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-252.625, -330.75],
                          [-253.75, -337],
                          [-259.75, -336.5],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-274.938, -334.063],
                          [-281.813, -341.063],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-302.75, -333.25],
                          [-304, -339.125],
                          [-310.375, -338.75],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-281.688, -380.563],
                        ],
                      },
                    ],
                    t: 210,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [2.625, 2.5],
                          [0, 0],
                          [0, 0],
                          [-22.875, -22.875],
                          [0, 0],
                          [0, 0],
                          [6.375, 7.563],
                          [0, 0],
                          [2.75, 2.625],
                          [0, 0],
                          [2.125, -1.125],
                          [2.375, 2.75],
                          [0, 0],
                          [0, 0],
                          [10.25, -3.875],
                          [0, 0],
                          [2.25, 2.688],
                          [0, 0],
                          [0, 0],
                          [2.5, 2.875],
                          [0, 0],
                          [0, 0],
                          [3.125, 4.625],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-2.625, -2.5],
                          [0, 0],
                          [0, 0],
                          [22.875, 22.875],
                          [0, 0],
                          [0, 0],
                          [-0.438, 0],
                          [0, 0],
                          [-2.75, -2.625],
                          [0, 0],
                          [-1.215, 0.643],
                          [-2.302, -2.666],
                          [0, 0],
                          [0, 0],
                          [-5.125, 4.875],
                          [0, 0],
                          [-2.25, -2.688],
                          [0, 0],
                          [0, 0],
                          [-2.5, -2.875],
                          [0, 0],
                          [0, 0],
                          [-1.5, -3.625],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-282.313, -387.063],
                          [-288.938, -386.313],
                          [-415.25, -261],
                          [-410, -210.5],
                          [-362.75, -208],
                          [-311.5, -258.75],
                          [-311.063, -270.125],
                          [-252.625, -330.75],
                          [-253.75, -337],
                          [-259.75, -336.5],
                          [-310.125, -287.375],
                          [-314.625, -288.625],
                          [-315.375, -293.5],
                          [-274.938, -334.063],
                          [-281.813, -341.063],
                          [-339.25, -284.5],
                          [-344.438, -286.125],
                          [-344.5, -290.875],
                          [-302.75, -333.25],
                          [-304, -339.125],
                          [-310.375, -338.75],
                          [-342.5, -306.125],
                          [-349.25, -308.375],
                          [-347.75, -313.75],
                          [-281.688, -380.563],
                        ],
                      },
                    ],
                    t: 239,
                  },
                ],
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 9, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 1,
      parent: 2,
    },
    {
      ty: 4,
      nm: 'Shape Layer 6',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [-378, -248, 0], ix: 1 },
        s: { a: 0, k: [43.89, 43.89, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0, y: 1 },
              s: [597, -127.5, 0],
              t: 0,
            },
            {
              o: { x: 1, y: 0 },
              i: { x: 0.507, y: 1 },
              s: [232, 218.5, 0],
              t: 60,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [-167, 577.5, 0],
              t: 239,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.437, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-213.121, -449.592],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-197.438, -477.313],
                          [-197.813, -484.688],
                          [-204.938, -483.438],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-119.125, -438.375],
                          [-119.875, -444.625],
                          [-125.75, -444],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-168.75, -415.75],
                          [-168.969, -422.219],
                          [-175.813, -420.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-203.5, -405.5],
                          [-204.375, -412],
                          [-211, -411.5],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-199.437, -437.313],
                          [-201.063, -448.563],
                        ],
                      },
                    ],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.438, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-195.434, -466.905],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-221.125, -453.5],
                          [-221.5, -460.875],
                          [-228.625, -459.625],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-144.25, -413],
                          [-145, -419.25],
                          [-150.875, -418.625],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-126.25, -457.75],
                          [-126.469, -464.219],
                          [-133.313, -462.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-192, -417],
                          [-192.875, -423.5],
                          [-199.5, -423],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-181.75, -454.625],
                          [-183.375, -465.875],
                        ],
                      },
                    ],
                    t: 30,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.437, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-213.121, -449.592],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-197.438, -477.313],
                          [-197.813, -484.688],
                          [-204.938, -483.438],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-119.125, -438.375],
                          [-119.875, -444.625],
                          [-125.75, -444],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-168.75, -415.75],
                          [-168.969, -422.219],
                          [-175.813, -420.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-203.5, -405.5],
                          [-204.375, -412],
                          [-211, -411.5],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-199.437, -437.313],
                          [-201.063, -448.563],
                        ],
                      },
                    ],
                    t: 60,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.438, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-195.434, -466.905],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-221.125, -453.5],
                          [-221.5, -460.875],
                          [-228.625, -459.625],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-144.25, -413],
                          [-145, -419.25],
                          [-150.875, -418.625],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-126.25, -457.75],
                          [-126.469, -464.219],
                          [-133.313, -462.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-192, -417],
                          [-192.875, -423.5],
                          [-199.5, -423],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-181.75, -454.625],
                          [-183.375, -465.875],
                        ],
                      },
                    ],
                    t: 90,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.437, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-213.121, -449.592],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-197.438, -477.313],
                          [-197.813, -484.688],
                          [-204.938, -483.438],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-119.125, -438.375],
                          [-119.875, -444.625],
                          [-125.75, -444],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-168.75, -415.75],
                          [-168.969, -422.219],
                          [-175.813, -420.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-203.5, -405.5],
                          [-204.375, -412],
                          [-211, -411.5],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-199.437, -437.313],
                          [-201.063, -448.563],
                        ],
                      },
                    ],
                    t: 120,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.438, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-195.434, -466.905],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-221.125, -453.5],
                          [-221.5, -460.875],
                          [-228.625, -459.625],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-144.25, -413],
                          [-145, -419.25],
                          [-150.875, -418.625],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-126.25, -457.75],
                          [-126.469, -464.219],
                          [-133.313, -462.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-192, -417],
                          [-192.875, -423.5],
                          [-199.5, -423],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-181.75, -454.625],
                          [-183.375, -465.875],
                        ],
                      },
                    ],
                    t: 150,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.437, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-213.121, -449.592],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-197.438, -477.313],
                          [-197.813, -484.688],
                          [-204.938, -483.438],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-119.125, -438.375],
                          [-119.875, -444.625],
                          [-125.75, -444],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-168.75, -415.75],
                          [-168.969, -422.219],
                          [-175.813, -420.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-203.5, -405.5],
                          [-204.375, -412],
                          [-211, -411.5],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-199.437, -437.313],
                          [-201.063, -448.563],
                        ],
                      },
                    ],
                    t: 180,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.438, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-195.434, -466.905],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-221.125, -453.5],
                          [-221.5, -460.875],
                          [-228.625, -459.625],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-144.25, -413],
                          [-145, -419.25],
                          [-150.875, -418.625],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-126.25, -457.75],
                          [-126.469, -464.219],
                          [-133.313, -462.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-192, -417],
                          [-192.875, -423.5],
                          [-199.5, -423],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-181.75, -454.625],
                          [-183.375, -465.875],
                        ],
                      },
                    ],
                    t: 210,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [4.108, -4.108],
                          [0, 0],
                          [4.188, 4.188],
                          [0, 0],
                          [0, 0.375],
                          [2.875, 2.75],
                          [0, 0],
                          [0, 0],
                          [-29.25, -29.25],
                          [0, 0],
                          [0, 0],
                          [2.625, 2.125],
                          [0, 0],
                          [0, 0],
                          [-3.75, 7.5],
                          [0, 0],
                          [3.344, 3.344],
                          [0, 0],
                          [0, 0],
                          [1, 2],
                          [0, 0],
                          [0, 0],
                          [2.437, 2.438],
                          [0, 0],
                          [0, 0],
                          [1.75, 2],
                          [0, 0],
                          [0, 0],
                          [6.375, 6.375],
                        ],
                        o: [
                          [-20.111, 20.111],
                          [0, 0],
                          [-2.813, -2.813],
                          [0, 0],
                          [0, -0.375],
                          [-2.875, -2.75],
                          [0, 0],
                          [0, 0],
                          [29.5, 29.5],
                          [0, 0],
                          [0, 0],
                          [-2.625, -2.125],
                          [0, 0],
                          [0, 0],
                          [5.125, -5.125],
                          [0, 0],
                          [-3.344, -3.344],
                          [0, 0],
                          [0, 0],
                          [-1.75, -3.25],
                          [0, 0],
                          [0, 0],
                          [-2.438, -2.438],
                          [0, 0],
                          [0, 0],
                          [-1.75, -2],
                          [0, 0],
                          [0, 0],
                          [-1.158, -1.158],
                        ],
                        v: [
                          [-195.434, -466.905],
                          [-265.125, -396.875],
                          [-272.375, -397.625],
                          [-271, -403.5],
                          [-221.125, -453.5],
                          [-221.5, -460.875],
                          [-228.625, -459.625],
                          [-422, -266],
                          [-416.5, -202.5],
                          [-351, -206],
                          [-144.25, -413],
                          [-145, -419.25],
                          [-150.875, -418.625],
                          [-212.5, -356.25],
                          [-219, -363.125],
                          [-126.25, -457.75],
                          [-126.469, -464.219],
                          [-133.313, -462.75],
                          [-232.125, -363.5],
                          [-238.375, -365.5],
                          [-236.625, -372.125],
                          [-192, -417],
                          [-192.875, -423.5],
                          [-199.5, -423],
                          [-239.75, -382.875],
                          [-246, -383.875],
                          [-246, -389.5],
                          [-181.75, -454.625],
                          [-183.375, -465.875],
                        ],
                      },
                    ],
                    t: 239,
                  },
                ],
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 9, ix: 5 },
              d: [],
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: 'Moon Outlines 2',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [107.407, 107.407, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [540, 540, 0], ix: 2 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 10',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.822, -1.665],
                    [0, 0],
                    [-0.731, -0.105],
                    [0, 0],
                    [1.329, -1.295],
                    [0, 0],
                    [-0.125, -0.728],
                    [0, 0],
                    [1.643, 0.865],
                    [0, 0],
                    [0.654, -0.343],
                    [0, 0],
                    [-0.314, 1.83],
                    [0, 0],
                    [0.528, 0.515],
                    [0, 0],
                    [-1.837, 0.267],
                    [0, 0],
                    [-0.326, 0.661],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.328, 0.661],
                    [0, 0],
                    [1.838, 0.267],
                    [0, 0],
                    [-0.527, 0.515],
                    [0, 0],
                    [0.314, 1.83],
                    [0, 0],
                    [-0.652, -0.343],
                    [0, 0],
                    [-1.643, 0.865],
                    [0, 0],
                    [0.125, -0.728],
                    [0, 0],
                    [-1.329, -1.295],
                    [0, 0],
                    [0.73, -0.105],
                    [0, 0],
                    [0.822, -1.665],
                  ],
                  v: [
                    [2.009, -12.448],
                    [4.657, -7.08],
                    [6.345, -5.855],
                    [12.268, -4.994],
                    [13.51, -1.174],
                    [9.223, 3.005],
                    [8.579, 4.988],
                    [9.591, 10.887],
                    [6.341, 13.248],
                    [1.042, 10.463],
                    [-1.043, 10.463],
                    [-6.341, 13.248],
                    [-9.591, 10.887],
                    [-8.579, 4.988],
                    [-9.223, 3.005],
                    [-13.51, -1.174],
                    [-12.268, -4.994],
                    [-6.345, -5.855],
                    [-4.658, -7.08],
                    [-2.009, -12.448],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9961, 0.7608, 0.4471], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [55, 55],
                    t: 0,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 34.143,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [55, 55],
                    t: 68.285,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 102.428,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [55, 55],
                    t: 136.572,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 170.715,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [55, 55],
                    t: 204.857,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100, 100],
                    t: 239,
                  },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [286.951, 853.138], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 11',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.981, -1.987],
                    [0, 0],
                    [-0.871, -0.126],
                    [0, 0],
                    [1.586, -1.547],
                    [0, 0],
                    [-0.149, -0.867],
                    [0, 0],
                    [1.961, 1.031],
                    [0, 0],
                    [0.779, -0.409],
                    [0, 0],
                    [-0.374, 2.184],
                    [0, 0],
                    [0.631, 0.614],
                    [0, 0],
                    [-2.193, 0.319],
                    [0, 0],
                    [-0.389, 0.789],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.39, 0.789],
                    [0, 0],
                    [2.192, 0.319],
                    [0, 0],
                    [-0.631, 0.614],
                    [0, 0],
                    [0.374, 2.184],
                    [0, 0],
                    [-0.779, -0.409],
                    [0, 0],
                    [-1.961, 1.031],
                    [0, 0],
                    [0.149, -0.867],
                    [0, 0],
                    [-1.586, -1.547],
                    [0, 0],
                    [0.871, -0.126],
                    [0, 0],
                    [0.981, -1.987],
                  ],
                  v: [
                    [2.397, -14.855],
                    [5.559, -8.449],
                    [7.571, -6.986],
                    [14.641, -5.96],
                    [16.122, -1.399],
                    [11.007, 3.586],
                    [10.237, 5.953],
                    [11.445, 12.993],
                    [7.566, 15.81],
                    [1.244, 12.486],
                    [-1.244, 12.486],
                    [-7.566, 15.81],
                    [-11.445, 12.993],
                    [-10.237, 5.953],
                    [-11.007, 3.586],
                    [-16.122, -1.399],
                    [-14.64, -5.96],
                    [-7.571, -6.986],
                    [-5.559, -8.449],
                    [-2.398, -14.855],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.9961, 0.7608, 0.4471], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [20, 20],
                    t: 34,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100, 100],
                    t: 68,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [20, 20],
                    t: 102,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100, 100],
                    t: 137,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [20, 20],
                    t: 171,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100, 100],
                    t: 205,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [20, 20],
                    t: 239,
                  },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [823.765, 203.426], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 3,
    },
    {
      ty: 4,
      nm: 'Moon Outlines',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [540, 540, 0], ix: 1 },
        s: { a: 0, k: [107.407, 107.407, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [540, 540, 0], ix: 2 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 12',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 3.301],
                    [0, 0],
                    [3.301, 0],
                    [0, -3.301],
                    [0, 0],
                    [-3.301, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, -3.301],
                    [-3.301, 0],
                    [0, 0],
                    [0, 3.301],
                    [3.301, 0],
                  ],
                  v: [
                    [5.977, 46.421],
                    [5.977, -46.421],
                    [0, -52.398],
                    [-5.977, -46.421],
                    [-5.977, 46.421],
                    [0, 52.398],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [1, 0.8275, 0.702], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [552.398, 233.503], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 13',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [1.533, 15.948],
                          [-33.12, 20.603],
                          [-33.12, -20.603],
                          [1.533, -13.155],
                        ],
                      },
                    ],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [33.12, 20.603],
                          [-33.12, 20.603],
                          [-33.12, -20.603],
                          [33.12, -20.603],
                        ],
                      },
                    ],
                    t: 137,
                  },
                ],
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.8824, 0.3059, 0.7922], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [585.519, 212.9], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 4,
    },
    {
      ty: 4,
      nm: 'Shape Layer 5',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      td: 1,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [540, 540, 0], ix: 2 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [616, 616], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.1098, 0.1922, 0.4667], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 28], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 5,
    },
    {
      ty: 4,
      nm: 'Shape Layer 4',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      tt: 1,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.071, y: 1 },
              s: [1096.75, 540, 0],
              t: 0,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [540, 540, 0],
              t: 138,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [
                      {
                        c: false,
                        i: [
                          [0, 0],
                          [-17.5, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [21.75, 0],
                          [0, 0],
                        ],
                        v: [
                          [-27.5, 64.5],
                          [2, 67.75],
                          [35.25, 62],
                        ],
                      },
                    ],
                    t: 138,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [
                      {
                        c: false,
                        i: [
                          [0, 0],
                          [-17.5, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [21.75, 0],
                          [0, 0],
                        ],
                        v: [
                          [-31, 47],
                          [-1.5, 66.25],
                          [31.75, 44.5],
                        ],
                      },
                    ],
                    t: 158,
                  },
                ],
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.1098, 0.1922, 0.4667], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.1098, 0.1922, 0.4667], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 2',
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [14.774, 0],
                    [0, -14.428],
                    [-14.774, 0],
                    [0, 14.428],
                  ],
                  o: [
                    [-14.774, 0],
                    [0, 14.428],
                    [14.774, 0],
                    [0, -14.428],
                  ],
                  v: [
                    [0, -26.125],
                    [-26.75, 0],
                    [0, 26.125],
                    [26.75, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.1098, 0.1922, 0.4667], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 138,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 148,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100, 100],
                    t: 158,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 205,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 215,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 225,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 360,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 370,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100, 100],
                    t: 380,
                  },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [101.25, 11.125], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [14.774, 0],
                    [0, -14.428],
                    [-14.774, 0],
                    [0, 14.428],
                  ],
                  o: [
                    [-14.774, 0],
                    [0, 14.428],
                    [14.774, 0],
                    [0, -14.428],
                  ],
                  v: [
                    [0, -26.125],
                    [-26.75, 0],
                    [0, 26.125],
                    [26.75, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.1098, 0.1922, 0.4667], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 138,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 148,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.833, y: 1 },
                    s: [100, 100],
                    t: 158,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 205,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 215,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 225,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 100],
                    t: 360,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 370,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100, 100],
                    t: 380,
                  },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-100, 11.125], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 6,
    },
    {
      ty: 4,
      nm: 'Shape Layer 3',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [540, 540, 0], ix: 2 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [],
      ind: 7,
    },
    {
      ty: 4,
      nm: 'Shape Layer 1',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      td: 1,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [540, 540, 0], ix: 2 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [616, 616], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.1098, 0.1922, 0.4667], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 28], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 8,
    },
    {
      ty: 4,
      nm: 'Shape Layer 2',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      tt: 1,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0, y: 1 },
              s: [1132, 540, 0],
              t: 0,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [540, 540, 0],
              t: 138,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 7',
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [9.375, 5.5],
                    [9.75, -7.625],
                    [-9.5, -5.375],
                    [-1.75, 4],
                    [-2.625, 2.625],
                  ],
                  o: [
                    [-9.375, -5.5],
                    [-9.75, 7.625],
                    [9.5, 5.375],
                    [1.75, -4],
                    [2.625, -2.625],
                  ],
                  v: [
                    [242.625, 81.25],
                    [214, 84.875],
                    [216.75, 124.5],
                    [232.25, 115.375],
                    [244.625, 105.25],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 16',
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-120.375, 65.875], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 6',
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [147.125, 80.125], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 15',
          ix: 4,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-147.875, 79.625], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 5',
          ix: 5,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [152.875, 47.875], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 14',
          ix: 6,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-153.875, 47.875], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 4',
          ix: 7,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [119.125, 65.375], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 9',
          ix: 8,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-148.125, -272.375], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 3',
          ix: 9,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-258.625, -244.375], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 8',
          ix: 10,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-471.625, -95.625], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 2',
          ix: 11,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-375.625, -129.125], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 7',
          ix: 12,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [285.875, -167.375], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 13',
          ix: 13,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-193.375, 375.875], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 12',
          ix: 14,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [73.125, 381.375], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 11',
          ix: 15,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [302.375, 283.125], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 10',
          ix: 16,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [457.375, 13.125], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Ellipse 1',
          ix: 17,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'el',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Ellipse',
              nm: 'Ellipse Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              s: { a: 0, k: [16.75, 16.75], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [94.366, 94.366], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [454.375, -157.875], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 10',
          ix: 18,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [-24.5, 15.875],
                  ],
                  o: [
                    [0, 0],
                    [-0.375, 0.5],
                  ],
                  v: [
                    [384.75, 96.75],
                    [426.75, 80.875],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-230.5, 86], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 12',
          ix: 19,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [27.5, 11.875],
                  ],
                  o: [
                    [0, 0],
                    [-0.375, 0.5],
                  ],
                  v: [
                    [-243, -142.75],
                    [-288, -193.125],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-230.5, 86], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 9',
          ix: 20,
          cix: 2,
          np: 4,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [27.5, 11.875],
                  ],
                  o: [
                    [0, 0],
                    [-0.375, 0.5],
                  ],
                  v: [
                    [107, 129.25],
                    [62, 78.875],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'gr',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Group',
              nm: 'Shape 9',
              ix: 4,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: 'sh',
                  bm: 0,
                  cl: '',
                  ln: '',
                  hd: false,
                  mn: 'ADBE Vector Shape - Group',
                  nm: 'Path 1',
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: false,
                      i: [
                        [0, 0],
                        [27.5, 11.875],
                      ],
                      o: [
                        [0, 0],
                        [-0.375, 0.5],
                      ],
                      v: [
                        [387, 255.25],
                        [342, 204.875],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: 'st',
                  bm: 0,
                  cl: '',
                  ln: '',
                  hd: false,
                  mn: 'ADBE Vector Graphic - Stroke',
                  nm: 'Stroke 1',
                  lc: 2,
                  lj: 2,
                  ml: 1,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 12, ix: 5 },
                  d: [],
                  c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
                },
                {
                  ty: 'fl',
                  bm: 0,
                  cl: '',
                  ln: '',
                  hd: true,
                  mn: 'ADBE Vector Graphic - Fill',
                  nm: 'Fill 1',
                  c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: 'tr',
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-230.5, 86], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-230.5, 86], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 23',
          ix: 21,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [9.125, 12.125],
                  ],
                  o: [
                    [0, 0],
                    [-0.375, 0.5],
                  ],
                  v: [
                    [-544.5, -298],
                    [-559.25, -324.625],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-230.5, 86], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 8',
          ix: 22,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [9.125, 12.125],
                  ],
                  o: [
                    [0, 0],
                    [-0.375, 0.5],
                  ],
                  v: [
                    [9.5, -181],
                    [-5.25, -207.625],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-230.5, 86], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 6',
          ix: 23,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [24.5, -11],
                  ],
                  o: [
                    [0, 0],
                    [-24.5, 11],
                  ],
                  v: [
                    [162.5, -191.75],
                    [121, -200.75],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 5',
          ix: 24,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [8.75, -3.25],
                    [10.25, -5.75],
                    [-1.25, -15],
                    [-6.25, 29.75],
                  ],
                  o: [
                    [-8.75, 3.25],
                    [-10.25, 5.75],
                    [8.25, 36.25],
                    [6.25, -29.75],
                  ],
                  v: [
                    [-81.25, -191.75],
                    [-104.75, -174.75],
                    [-134.75, -149.25],
                    [-53.25, -168.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 4',
          ix: 25,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.25, -0.75],
                    [-0.5, -10.5],
                    [-39.5, -3.75],
                    [24.25, 12],
                    [11.5, 6],
                  ],
                  o: [
                    [-5.25, 0.75],
                    [0.5, 13],
                    [39.5, 3.75],
                    [-10.25, -3.5],
                    [-11.5, -6],
                  ],
                  v: [
                    [133.5, -174.25],
                    [123.5, -158.5],
                    [192.5, -92.75],
                    [205.25, -146],
                    [160.5, -163.25],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 21',
          ix: 26,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [45, 38],
                    [0, 0],
                    [-59.5, -37],
                    [0, 0],
                  ],
                  o: [
                    [-36.233, -30.597],
                    [0, 0],
                    [50.5, 19.5],
                    [0, 0],
                  ],
                  v: [
                    [-268, -114],
                    [-340, -101],
                    [-340, 16],
                    [-261, -11],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [-303.134, -34.447], ix: 1 },
              s: { a: 0, k: [63.62, 63.62], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-470, -392], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 15',
          ix: 27,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [45, 38],
                    [0, 0],
                    [-59.5, -37],
                    [0, 0],
                  ],
                  o: [
                    [-36.233, -30.597],
                    [0, 0],
                    [50.5, 19.5],
                    [0, 0],
                  ],
                  v: [
                    [-268, -114],
                    [-340, -101],
                    [-340, 16],
                    [-261, -11],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [-303.134, -34.447], ix: 1 },
              s: { a: 0, k: [63.62, 63.62], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-182, -328], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 14',
          ix: 28,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [45, 38],
                    [0, 0],
                    [-59.5, -37],
                    [0, 0],
                  ],
                  o: [
                    [-36.233, -30.597],
                    [0, 0],
                    [50.5, 19.5],
                    [0, 0],
                  ],
                  v: [
                    [-268, -114],
                    [-340, -101],
                    [-340, 16],
                    [-261, -11],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [-303.134, -34.447], ix: 1 },
              s: { a: 0, k: [87.09, 87.09], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-392, -226], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 3',
          ix: 29,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [45, 38],
                    [0, 0],
                    [-59.5, -37],
                    [0, 0],
                  ],
                  o: [
                    [-36.233, -30.597],
                    [0, 0],
                    [50.5, 19.5],
                    [0, 0],
                  ],
                  v: [
                    [-268, -114],
                    [-340, -101],
                    [-340, 16],
                    [-261, -11],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 18',
          ix: 30,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [61, 46],
                    [2, -11.5],
                    [-20.226, -18.039],
                    [-20, 8.5],
                  ],
                  o: [
                    [-48.836, -36.827],
                    [-2, 11.5],
                    [18.5, 16.5],
                    [22, -3.5],
                  ],
                  v: [
                    [-187, 173.5],
                    [-273.5, 204.5],
                    [-264, 268.5],
                    [-156, 273.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [63.724, 63.724], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-714, -269], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 17',
          ix: 31,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [61, 46],
                    [2, -11.5],
                    [-20.226, -18.039],
                    [-20, 8.5],
                  ],
                  o: [
                    [-48.836, -36.827],
                    [-2, 11.5],
                    [18.5, 16.5],
                    [22, -3.5],
                  ],
                  v: [
                    [-187, 173.5],
                    [-273.5, 204.5],
                    [-264, 268.5],
                    [-156, 273.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [63.724, 63.724], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-744, -76], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 22',
          ix: 32,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [61, 46],
                    [2, -11.5],
                    [-20.226, -18.039],
                    [-20, 8.5],
                  ],
                  o: [
                    [-48.836, -36.827],
                    [-2, 11.5],
                    [18.5, 16.5],
                    [22, -3.5],
                  ],
                  v: [
                    [-187, 173.5],
                    [-273.5, 204.5],
                    [-264, 268.5],
                    [-156, 273.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [63.724, 63.724], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-876, -338], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 26',
          ix: 33,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [61, 46],
                    [2, -11.5],
                    [-20.226, -18.039],
                    [-20, 8.5],
                  ],
                  o: [
                    [-48.836, -36.827],
                    [-2, 11.5],
                    [18.5, 16.5],
                    [22, -3.5],
                  ],
                  v: [
                    [-187, 173.5],
                    [-273.5, 204.5],
                    [-264, 268.5],
                    [-156, 273.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [63.724, 63.724], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [136, 348], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 25',
          ix: 34,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [61, 46],
                    [2, -11.5],
                    [-20.226, -18.039],
                    [-20, 8.5],
                  ],
                  o: [
                    [-48.836, -36.827],
                    [-2, 11.5],
                    [18.5, 16.5],
                    [22, -3.5],
                  ],
                  v: [
                    [-187, 173.5],
                    [-273.5, 204.5],
                    [-264, 268.5],
                    [-156, 273.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [63.724, 63.724], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-642, 108], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 13',
          ix: 35,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [61, 46],
                    [2, -11.5],
                    [-20.226, -18.039],
                    [-20, 8.5],
                  ],
                  o: [
                    [-48.836, -36.827],
                    [-2, 11.5],
                    [18.5, 16.5],
                    [22, -3.5],
                  ],
                  v: [
                    [-187, 173.5],
                    [-273.5, 204.5],
                    [-264, 268.5],
                    [-156, 273.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [63.724, 63.724], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-298, -18], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 2',
          ix: 36,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [61, 46],
                    [2, -11.5],
                    [-20.226, -18.039],
                    [-20, 8.5],
                  ],
                  o: [
                    [-48.836, -36.827],
                    [-2, 11.5],
                    [18.5, 16.5],
                    [22, -3.5],
                  ],
                  v: [
                    [-187, 173.5],
                    [-273.5, 204.5],
                    [-264, 268.5],
                    [-156, 273.5],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 16',
          ix: 37,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [28.75, -7],
                    [59.166, -23.879],
                    [-71.492, 27.254],
                    [-7.75, 12],
                  ],
                  o: [
                    [-31.813, 7.746],
                    [-55.75, 22.5],
                    [45.25, -17.25],
                    [7.75, -12],
                  ],
                  v: [
                    [243.75, 163.5],
                    [162.25, 205.5],
                    [208.25, 281],
                    [267, 235],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [210, 232], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-644, -182], ix: 2 },
              r: { a: 0, k: -74.94, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 20',
          ix: 38,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [28.75, -7],
                    [59.166, -23.879],
                    [-71.492, 27.254],
                    [-7.75, 12],
                  ],
                  o: [
                    [-31.813, 7.746],
                    [-55.75, 22.5],
                    [45.25, -17.25],
                    [7.75, -12],
                  ],
                  v: [
                    [243.75, 163.5],
                    [162.25, 205.5],
                    [208.25, 281],
                    [267, 235],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [210, 232], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-332, 432], ix: 2 },
              r: { a: 0, k: -74.94, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 19',
          ix: 39,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [28.75, -7],
                    [59.166, -23.879],
                    [-71.492, 27.254],
                    [-7.75, 12],
                  ],
                  o: [
                    [-31.813, 7.746],
                    [-55.75, 22.5],
                    [45.25, -17.25],
                    [7.75, -12],
                  ],
                  v: [
                    [243.75, 163.5],
                    [162.25, 205.5],
                    [208.25, 281],
                    [267, 235],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [210, 232], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-544, 342], ix: 2 },
              r: { a: 0, k: -74.94, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 11',
          ix: 40,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [28.75, -7],
                    [59.166, -23.879],
                    [-71.492, 27.254],
                    [-7.75, 12],
                  ],
                  o: [
                    [-31.813, 7.746],
                    [-55.75, 22.5],
                    [45.25, -17.25],
                    [7.75, -12],
                  ],
                  v: [
                    [243.75, 163.5],
                    [162.25, 205.5],
                    [208.25, 281],
                    [267, 235],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [210, 232], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-622, 72], ix: 2 },
              r: { a: 0, k: -74.94, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 24',
          ix: 41,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [28.75, -7],
                    [59.166, -23.879],
                    [-71.492, 27.254],
                    [-7.75, 12],
                  ],
                  o: [
                    [-31.813, 7.746],
                    [-55.75, 22.5],
                    [45.25, -17.25],
                    [7.75, -12],
                  ],
                  v: [
                    [243.75, 163.5],
                    [162.25, 205.5],
                    [208.25, 281],
                    [267, 235],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [-854, -598], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Shape 1',
          ix: 42,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [28.75, -7],
                    [59.166, -23.879],
                    [-71.492, 27.254],
                    [-7.75, 12],
                  ],
                  o: [
                    [-31.813, 7.746],
                    [-55.75, 22.5],
                    [45.25, -17.25],
                    [7.75, -12],
                  ],
                  v: [
                    [243.75, 163.5],
                    [162.25, 205.5],
                    [208.25, 281],
                    [267, 235],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.498, 0.5843, 0.9647], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Rectangle 1',
          ix: 43,
          cix: 2,
          np: 3,
          it: [
            {
              ty: 'rc',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Rect',
              nm: 'Rectangle Path 1',
              d: 1,
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              s: { a: 0, k: [2448, 2448], ix: 2 },
            },
            {
              ty: 'st',
              bm: 0,
              cl: '',
              ln: '',
              hd: true,
              mn: 'ADBE Vector Graphic - Stroke',
              nm: 'Stroke 1',
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 12, ix: 5 },
              d: [],
              c: { a: 0, k: [1, 1, 1], ix: 3 },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [121.053, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [4, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 9,
    },
    {
      ty: 4,
      nm: 'Moon Outlines 5',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [826.759, 309.103, 0], ix: 1 },
        s: { a: 0, k: [107.407, 107.407, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0, y: 1 },
              s: [-246, 824.514, 0],
              t: 0,
            },
            {
              o: { x: 0.333, y: 0.333 },
              i: { x: 0.667, y: 0.667 },
              s: [848, 292, 0],
              t: 60,
            },
            {
              o: { x: 1, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [848, 292, 0],
              t: 180,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1287, 63, 0],
              t: 239,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [-207], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.833, y: 1 }, s: [0], t: 60 },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [162],
              t: 239,
            },
          ],
          ix: 10,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 1',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.007, 0.013],
                    [0, 0],
                    [0.631, -0.2],
                    [0.065, -4.838],
                    [0, 0],
                    [-0.013, -0.17],
                    [-4.462, -0.051],
                    [0, 0],
                    [-0.285, 0.037],
                    [-0.044, 5.343],
                    [0, 0],
                    [0.102, 0.56],
                  ],
                  o: [
                    [0, 0],
                    [-0.702, 0.003],
                    [-4.186, 1.325],
                    [0, 0],
                    [0.002, 0.169],
                    [0.358, 4.766],
                    [0, 0],
                    [0.282, -0.003],
                    [4.333, -0.571],
                    [0, 0],
                    [-0.005, -0.594],
                    [-0.825, -4.542],
                  ],
                  v: [
                    [1.63, -10.306],
                    [1.567, -10.306],
                    [-0.452, -9.997],
                    [-8.93, 0.541],
                    [-8.93, 0.769],
                    [-8.908, 1.277],
                    [-0.185, 10.306],
                    [-0.021, 10.306],
                    [0.83, 10.245],
                    [8.93, -1.921],
                    [8.93, -2.111],
                    [8.772, -3.848],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.4471, 0.5373, 0.9608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [838.204, 295.097], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 2',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [18.448, 16.142],
                    [-21.266, 8.67],
                    [-5.637, -14.861],
                  ],
                  o: [
                    [-9.004, -7.878],
                    [21.268, -8.671],
                    [5.637, 14.861],
                  ],
                  v: [
                    [-35.725, 23.061],
                    [-14.203, -30.532],
                    [39.093, -14.861],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [823.615, 307.644], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 10,
    },
    {
      ty: 4,
      nm: 'Moon Outlines 6',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [897.75, 212.392, 0], ix: 1 },
        s: { a: 0, k: [107.407, 107.407, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0, y: 1 },
              s: [-56.75, 621.125, 0],
              t: 0,
            },
            {
              o: { x: 0.333, y: 0.333 },
              i: { x: 0.667, y: 0.667 },
              s: [924.25, 188.125, 0],
              t: 60,
            },
            {
              o: { x: 1, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [924.25, 188.125, 0],
              t: 180,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1200.25, 52.125, 0],
              t: 239,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [-122], t: 0 },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 60,
            },
          ],
          ix: 10,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 8',
          ix: 1,
          cix: 2,
          np: 4,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [4.651, 0.038],
                    [0, 0],
                    [1.132, -0.532],
                    [0.061, -7.779],
                    [0, 0],
                    [-1.396, -1.588],
                    [-1.99, -0.024],
                    [0, 0],
                    [-1.582, 8.162],
                  ],
                  o: [
                    [0, 0],
                    [-1.432, 0.012],
                    [-5.736, 2.694],
                    [0, 0],
                    [0.016, 2.018],
                    [1.409, 1.602],
                    [0, 0],
                    [7.585, -0.088],
                    [1.523, -7.866],
                  ],
                  v: [
                    [5.057, -14.513],
                    [4.882, -14.513],
                    [0.934, -13.672],
                    [-13.52, 6.525],
                    [-13.52, 6.688],
                    [-11.505, 12.177],
                    [-6.303, 14.513],
                    [-6.094, 14.513],
                    [13.244, -3.543],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.8235, 0.8588, 0.9882], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [898.139, 212.369], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 11,
    },
    {
      ty: 4,
      nm: 'Moon Outlines 7',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [897.517, 365.897, 0], ix: 1 },
        s: { a: 0, k: [107.407, 107.407, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0, y: 1 },
              s: [-47, 955, 0],
              t: 0,
            },
            {
              o: { x: 0.333, y: 0.333 },
              i: { x: 0.667, y: 0.667 },
              s: [924, 353, 0],
              t: 60,
            },
            {
              o: { x: 1, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [924, 353, 0],
              t: 180,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1117.5, 217, 0],
              t: 239,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [-189], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 60 },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [127],
              t: 239,
            },
          ],
          ix: 10,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 9',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.074, -4.384],
                    [-9.737, -3.331],
                    [3.417, 10.019],
                  ],
                  o: [
                    [-4.611, 6.574],
                    [9.736, 3.331],
                    [-3.416, -10.019],
                  ],
                  v: [
                    [-5.722, -12.299],
                    [-3.672, 16.313],
                    [9.993, -9.625],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [897.795, 362.822], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 12,
    },
    {
      ty: 4,
      nm: 'Moon Outlines 4',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [188.767, 843.284, 0], ix: 1 },
        s: { a: 0, k: [107.407, 107.407, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0, y: 1 },
              s: [-199.25, 1106.75, 0],
              t: 0,
            },
            {
              o: { x: 0.333, y: 0.333 },
              i: { x: 0.667, y: 0.667 },
              s: [162.75, 865.75, 0],
              t: 60,
            },
            {
              o: { x: 0.999, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [162.75, 865.75, 0],
              t: 146,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1118.5, 185, 0],
              t: 215,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [-230], t: 0 },
            { o: { x: 0.333, y: 0 }, i: { x: 0.833, y: 1 }, s: [0], t: 60 },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [207],
              t: 239,
            },
          ],
          ix: 10,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 7',
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [6.484, 0.029],
                    [0, 0],
                    [1.7, -4.109],
                    [0.005, -0.844],
                    [0, 0],
                    [-9.237, 0],
                    [-1.335, 0.364],
                    [-0.014, 3.245],
                    [0, 0],
                    [1.989, 1.59],
                  ],
                  o: [
                    [0, 0],
                    [-4.972, 0.022],
                    [-0.359, 0.868],
                    [0, 0],
                    [0.039, 7.161],
                    [1.558, 0],
                    [5.317, -1.448],
                    [0, 0],
                    [-0.013, -2.965],
                    [-3.523, -2.816],
                  ],
                  v: [
                    [-4.565, -10.734],
                    [-4.781, -10.734],
                    [-15.867, -5.062],
                    [-16.401, -2.49],
                    [-16.401, -2.419],
                    [4.792, 10.734],
                    [9.166, 10.2],
                    [16.401, 2.266],
                    [16.401, 2.196],
                    [12.818, -5.317],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.8235, 0.8588, 0.9882], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [188.558, 842.31], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 13,
    },
    {
      ty: 3,
      nm: 'Null 3',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [46, 56, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0.333 },
              i: { x: 0.667, y: 0.667 },
              s: [178, 771, 0],
              t: 60,
            },
            {
              o: { x: 0.738, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [178, 771, 0],
              t: 180,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [1156, -77, 0],
              t: 239,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 60 },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [126],
              t: 239,
            },
          ],
          ix: 10,
        },
      },
      ef: [],
      ind: 14,
    },
    {
      ty: 4,
      nm: 'Moon Outlines 3',
      mn: '',
      sr: 1,
      st: 0,
      op: 240,
      ip: 0,
      hd: false,
      cl: '',
      ln: '',
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [193.655, 756, 0], ix: 1 },
        s: { a: 0, k: [107.407, 107.407, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.115, y: 1 },
              s: [-340, 371, 0],
              t: 0,
            },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [36, 57, 0],
              t: 60,
            },
          ],
          ix: 2,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.115, y: 1 }, s: [-134], t: 0 },
            {
              o: { x: 0.167, y: 0.167 },
              i: { x: 0.833, y: 0.833 },
              s: [0],
              t: 60,
            },
          ],
          ix: 10,
        },
      },
      ef: [],
      shapes: [
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 3',
          ix: 1,
          cix: 2,
          np: 4,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [5.903, 0.039],
                    [0, 0],
                    [0.944, -0.105],
                    [0.05, -7.083],
                    [0, 0],
                    [-1.457, -2.429],
                    [-6.228, 0],
                    [-0.885, 0.369],
                    [4.442, 8.882],
                  ],
                  o: [
                    [0, 0],
                    [-0.848, 0.005],
                    [-5.879, 0.654],
                    [0, 0],
                    [0.018, 2.498],
                    [4.825, 8.042],
                    [1.047, 0],
                    [6.149, -2.562],
                    [-3.838, -7.68],
                  ],
                  v: [
                    [-1.119, -18.121],
                    [-1.38, -18.121],
                    [-4.063, -17.952],
                    [-15.065, -3.8],
                    [-15.065, -3.589],
                    [-12.945, 3.913],
                    [6.691, 18.121],
                    [9.603, 17.578],
                    [12.335, -7.702],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.4471, 0.5373, 0.9608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [177.038, 740.831], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 4',
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.935, 2.456],
                    [0.036, -4.651],
                    [0, 0],
                    [-1.518, -3.836],
                    [-5.703, 0],
                    [-3.712, 3.456],
                    [0.005, 0.013],
                    [6.337, 6.159],
                  ],
                  o: [
                    [-6.331, 1.189],
                    [0, 0],
                    [0.027, 3.501],
                    [2.094, 5.287],
                    [4.062, 0],
                    [-0.005, -0.013],
                    [-2.888, -5.83],
                    [-1.763, -1.714],
                  ],
                  v: [
                    [-5.372, -14.76],
                    [-13.905, -5.196],
                    [-13.905, -4.958],
                    [-11.161, 6.384],
                    [1.79, 14.76],
                    [13.905, 9.737],
                    [13.889, 9.7],
                    [0.142, -8.411],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.4471, 0.5373, 0.9608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [242.559, 752.709], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: 'gr',
          bm: 0,
          cl: '',
          ln: '',
          hd: false,
          mn: 'ADBE Vector Group',
          nm: 'Group 6',
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: 'sh',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Shape - Group',
              nm: 'Path 1',
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [14.728, 30.533],
                    [-30.892, -1.797],
                    [-10.93, -10.623],
                    [15.805, -9.34],
                  ],
                  o: [
                    [-11.135, -23.083],
                    [30.893, 1.795],
                    [22.823, 22.184],
                    [-15.806, 9.339],
                  ],
                  v: [
                    [-61.246, -4.849],
                    [-5.209, -50.828],
                    [49.558, -9.932],
                    [55.858, 43.286],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: 'fl',
              bm: 0,
              cl: '',
              ln: '',
              hd: false,
              mn: 'ADBE Vector Graphic - Fill',
              nm: 'Fill 1',
              c: { a: 0, k: [0.6, 0.6784, 0.9765], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: 'tr',
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [193.143, 754.23], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 15,
      parent: 14,
    },
  ],
  ddd: 0,
  h: 1080,
  w: 1080,
  meta: {
    a: '',
    k: '',
    d: '',
    g: '@lottiefiles/toolkit-js 0.21.3',
    tc: '#000000',
  },
  v: '5.2.1',
  fr: 60,
  op: 240,
  ip: 0,
  assets: [],
};